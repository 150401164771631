.trusted-by-logos {
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.trusted-by-container {
    padding-bottom: 100px;
}

.responsive-imags {
    width: auto !important;
}

@media (max-width: 767px) {
    .responsive-imags {
        height: 70px;
    }

    .trusted-by-container {
        padding-top: 60px !important;
        padding-bottom: 64px !important;
    }
}